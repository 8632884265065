import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-auth-root",
  templateUrl: "./auth-root.component.html",
  styleUrls: ["./auth-root.component.scss"],
})
export class AuthRootComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
