import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgForm } from "@angular/forms";
import * as moment from "moment-timezone";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export class ViaCEP {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}

@Injectable({
  providedIn: "root",
})
export class HelperService {
  moment;
  emailRegex: RegExp;
  urlRegex: RegExp;
  config = {
    placeholder: "",
    tabsize: 2,
    height: "340px",
    uploadImagePath: "/api/upload",
    tooltip: false,
    toolbar: [
      ["misc", ["codeview"]],
      ["style", ["bold", "italic", "underline"]],
      ["para", ["style", "ul", "paragraph"]],
      ["insert", ["link", "picture", "video"]],
    ],
  };

  constructor(private _snackBar: MatSnackBar, private http: HttpClient) {
    moment.locale("pt-BR");
    this.moment = moment;
    this.emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.urlRegex = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "OK", {
      duration: 3200,
      horizontalPosition: "end",
      verticalPosition: "top",
      panelClass: ["white-snackbar"],
    });
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object)
          .values(control.controls)
          .forEach((c) => this.formMarkAllTouched(c));
      }
    });
  }

  viaCep(cep: string) {
    return this.http.get<ViaCEP>(`http://viacep.com.br/ws/${cep}/json/`);
  }

  compareFn(v1: any, v2: any): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }

  isValidCPF(cpf: string) {
    if (!cpf || cpf == "") {
      return true;
    }
    if (typeof cpf !== "string") return false;
    cpf = cpf.replace(/[\s.-]*/gim, "");
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  displayFormattedDate(date?: Date, format?: string) {
    const formattedDate = moment
      .utc(date)
      .tz(this.getClientTz())
      .format(format);

    return formattedDate;
  }

  getClientTz() {
    return moment.tz.guess() || "America/Sao_Paulo";
  }

  removeNullKeys(params: Object) {
    for (let key in params) {
      if (
        params[key] === undefined ||
        params[key] === null ||
        params[key] === ""
      ) {
        delete params[key];
      }
    }
    return params;
  }

  subscribeToObservable<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const subscription = observable.subscribe({
        next: (value) => {
          resolve(value); // Resolve the promise with the emitted value
          subscription.unsubscribe(); // Unsubscribe once value is received
        },
        error: (error) => {
          reject(error); // Reject the promise if there's an error
        },
      });
    });
  }
}
