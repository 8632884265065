import { InjectionToken } from "@angular/core";

export interface CurrencyMaskConfig {
  align: string;
  allowNegative: boolean;
  allowZero: boolean;
  decimal: string;
  precision: number;
  prefix: string;
  suffix: string;
  thousands: string;
  nullable: boolean;
  min?: number;
  max?: number;
  inputMode?: CurrencyMaskInputMode;
}

export enum CurrencyMaskInputMode {
  FINANCIAL = 0,
  NATURAL = 1,
}

export const CURRENCY_MASK_CONFIG = new InjectionToken<
  Partial<CurrencyMaskConfig>
>("currency-mask.config");
