<div class="date-picker-header">
  <!-- <button mat-icon-button (click)="previousClicked($event, 'year')">
    <mat-icon>keyboard_double_arrow_left</mat-icon>
  </button> -->
  <button mat-icon-button (click)="previousClicked($event, 'month')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <span class="date-picker-header__label">{{ periodLabel }}</span>
  <button mat-icon-button (click)="nextClicked($event, 'month')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <!-- <button mat-icon-button (click)="nextClicked($event, 'year')">
    <mat-icon>keyboard_double_arrow_right</mat-icon>
  </button> -->
</div>
