import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { GlobalService } from "src/app/services/global.service";

interface RouteProps {
  name: string;
  icon: string;
  route?: string;
  group?: RouteProps[];
  openGroup?: boolean;
  queryParams?: any;
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  search: string = "";
  pages: RouteProps[] = [];

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.buildPages();
  }

  ngOnInit() {
    this.pages.forEach((page) => {
      if (page.group && page.group.length) {
        const routeSelected = page.group.find((item) =>
          item.route?.startsWith(this.router.url)
        );
        if (routeSelected) {
          page.openGroup = true;
        }
      }
    });
  }

  logout() {
    this.global.menuOpen = false;
    this.auth.logout();
    this.router.navigate(["/auth/sign-in"]);
  }

  onSearchChange(ev) {
    if (!ev) {
      this.buildPages();
    }
  }

  isGroupActive(page: RouteProps) {
    const isActive =
      page?.group &&
      page?.group.some((item) => this.router.url.startsWith(item.route ?? ""));
    return isActive;
  }

  buildPages() {

    this.pages = [
      { name: "Home", icon: "fa-solid fa-home", route: "/" },
      {
        name: "Serviços",
        icon: "fa-solid fa-map-marked-alt",
        route: "/servicos",
        queryParams: { status: "B,A,I" },
      },
      {
        name: "Financeiro",
        icon: "fa-solid fa-money-check-dollar",
        group: [
          {
            name: "Contas a pagar",
            icon: "fa-solid fa-money-check-dollar",
            route: "/contas-a-pagar",
          },
          {
            name: "Contas a receber",
            icon: "fa-solid fa-money-check",
            route: "/contas-a-receber",
          },
        ],
        openGroup: false,
      },
      {
        name: "Cadastros",
        icon: "fa-solid fa-layer-group",
        group: [
          {
            name: "Empresas",
            icon: "fa-solid fa-users",
            route: "/clientes",
          },
          {
            name: "Motoristas",
            icon: "fa-solid fa-car-side",
            route: "/usuarios",
            queryParams: { ind: "indMotorista" },
          },
          {
            name: "Chappa",
            icon: "fa-solid fa-user-astronaut",
            route: "/usuarios",
            queryParams: { ind: "indChappa" },
          },
          {
            name: "Necessidade chappa",
            icon: "fa-solid fa-leaf",
            route: "/necessidades-chappa",
          },
          {
            name: "Configuração de notificação",
            icon: "fa-solid fa-leaf",
            route: "/config-notificacaos",
          }
        ],
        openGroup: false,
      },
      {
        name: "Usuários",
        icon: "fa-solid fa-users",
        group: [
          {
            name: "Usuários administradores",
            icon: "fa-solid fa-user-shield",
            route: "/usuarios",
            queryParams: { ind: "indAdmin" },
          },
          {
            name: "Usuários empresas",
            icon: "fa-solid fa-user-tie",
            route: "/usuarios",
            queryParams: { ind: "indCliente" },
          },
        ],
        openGroup: false,
      },
      {
        name: "Configurações",
        icon: "fa-solid fa-cogs",
        group: [
          {
            name: "Email",
            icon: "fa-solid fa-envelope",
            route: "/configuracao",
          },
          {
            name: "Cadastros gerais",
            icon: "fa-solid fa-cog",
            route: "/cadastro-geral",
          },
        ],
        openGroup: false,
      },
    ];
  }

}
