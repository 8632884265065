import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { DateRange } from '@angular/material/datepicker';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {
  constructor(private helper: HelperService) {}

  getDateRangeValue(activeMode: string, dateRange?: DateRange<Date>) {
    let value = new DateRange(undefined, undefined);

    if (activeMode === 'today') {
      value = new DateRange(new Date(), new Date());
    }

    if (activeMode === 'single-date') {
      const date = dateRange?.start ?? new Date();

      value = new DateRange(date, date);
    }

    if (activeMode === 'current-week') {
      const startDate = this.helper.moment().startOf('week').toDate();
      const endDate = this.helper.moment().endOf('week').toDate();
      value = new DateRange(startDate, endDate);
    }

    if (activeMode === 'last-week') {
      const startDate = this.helper.moment().subtract(7, 'days').toDate();
      value = new DateRange(startDate, new Date());
    }

    if (activeMode === 'current-month') {
      const startDate = this.helper.moment().startOf('month').toDate();
      const endDate = this.helper.moment().endOf('month').toDate();
      value = new DateRange(startDate, endDate);
    }

    if (activeMode === 'last-30-days') {
      const startDate = this.helper.moment().subtract(30, 'days').toDate();
      const endDate = this.helper.moment().toDate();
      value = new DateRange(startDate, endDate);
    }

    if (activeMode === 'last-month') {
      const lastMonth = this.helper.moment().subtract(1, 'months');
      const startDate = lastMonth.startOf('month').toDate();
      const endDate = lastMonth.endOf('month').toDate();
      value = new DateRange(startDate, endDate);
    }

    if (activeMode === 'customized') {
      value = new DateRange(
        dateRange?.start ?? new Date(),
        dateRange?.end ?? new Date()
      );
    }

    return value;
  }

  getDataRangeActiveMode(initialDate: Date, finalDate: Date) {
    const isSingleDate = this.helper
      .moment(initialDate)
      .isSame(finalDate, 'day');

    if (isSingleDate) {
      return 'single-date';
    }

    const startOfWeekDate = this.helper.moment().startOf('week').toDate();
    const endOfWeekDate = this.helper.moment().endOf('week').toDate();

    const isCurrentWeek =
      this.helper.moment(initialDate).isSame(startOfWeekDate, 'day') &&
      this.helper.moment(finalDate).isSame(endOfWeekDate, 'day');

    if (isCurrentWeek) {
      return 'current-week';
    }

    const startOfLastWeekDate = this.helper
      .moment()
      .subtract(7, 'days')
      .toDate();

    const isLastWeek =
      this.helper.moment(initialDate).isSame(startOfLastWeekDate, 'D') &&
      this.helper.moment(finalDate).isSame(new Date(), 'day');

    if (isLastWeek) {
      return 'last-week';
    }

    const startOfMonthDate = this.helper.moment().startOf('month').toDate();
    const endOfMonthDate = this.helper.moment().endOf('month').toDate();

    const isCurrentMonth =
      this.helper.moment(initialDate).isSame(startOfMonthDate, 'day') &&
      this.helper.moment(finalDate).isSame(endOfMonthDate, 'day');

    if (isCurrentMonth) {
      return 'current-month';
    }

    const startOfLastMonthDate = this.helper
      .moment()
      .subtract(1, 'months')
      .startOf('month')
      .toDate();

    const endOfLastMonthDate = this.helper
      .moment()
      .subtract(1, 'months')
      .endOf('month')
      .toDate();

    const isLastMonth =
      this.helper.moment(initialDate).isSame(startOfLastMonthDate, 'D') &&
      this.helper.moment(finalDate).isSame(endOfLastMonthDate, 'day');

    if (isLastMonth) {
      return 'last-month';
    }

    const startOfLast30Days = this.helper
      .moment()
      .subtract(30, 'days')
      .toDate();
    const endOfLast30Days = new Date();

    const isLast30Days =
      this.helper.moment(initialDate).isSame(startOfLast30Days, 'day') &&
      this.helper.moment(finalDate).isSame(endOfLast30Days, 'day');

    if (isLast30Days) {
      return 'last-30-days';
    }

    return 'customized';
  }
}
