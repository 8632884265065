enum TypeUserRole {
  ADMIN = "ADMIN",
  FINANCEIRO = "FINANCEIRO",
  OPERACIONAL = "OPERACIONAL",
  CLIENTE_ADMIN = "CLIENTE_ADMIN",
  CLIENTE = "CLIENTE",
  MOTORISTA = "MOTORISTA",
  CHAPPA = "CHAPPA"
}

export default TypeUserRole;
