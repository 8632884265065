import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth.service";
import { GlobalService } from "../global.service";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  url = `${environment.baseUrl}/imagem/upload`;

  constructor(
    public global: GlobalService,
    public auth: AuthService,
    public http: HttpClient
  ) { }

  upload(fileToUpload: File, fileName: string = "image") {
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);

    const options = {
      method: "POST",
      httpParams: new HttpParams(),
    };

    const req = new HttpRequest(options.method, this.url, formData, {
      params: options.httpParams,
      reportProgress: true,
      headers: this.auth.getAuthorizationHeader(),
    });

    return this.http.request(req).pipe(
      map((r) => r),
      catchError((e) => this.handleError(e))
    );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }


  uploadMultipleFiles(files: File[], url: string) {
    const formData: FormData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append(`file${i}[]`, files[i], files[i].name);
    }

    const options = {
      method: 'POST',
      httpParams: new HttpParams()
    }

    const req = new HttpRequest(options.method, this.global.apiUrl + url, formData, {
      params: options.httpParams,
      reportProgress: true,
      headers: this.auth.getAuthorizationHeader()
    });

    return this.http.request(req).pipe(map(r => r), catchError((e) => this.handleError(e)))
  }

}
