import { Injectable } from "@angular/core";

export interface SelectOption {
  name: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  apiUrl: string = "https://apiv2.chappa.com.br";
  // apiUrl: string = 'http://localhost:50633';
  imageUrl: string = "https://apiv2.chappa.com.br/";
  menuOpen: boolean = false;

  constructor() { }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}

export const situationOptions: SelectOption[] = [
  { name: "Ativo", value: "A" },
  { name: "Inativo", value: "I" },
];
