import { NgModule } from "@angular/core";

import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from "@angular/material/core";
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
// imports
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { NgxMaskModule } from "ngx-mask";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
// locale
import { LOCALE_ID } from "@angular/core";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MaterialModule } from "./material/material.module";
import { SharedModule } from "./shared/shared.module";
import { AuthGuard } from "./guards/auth.guard";
import { GuestGuard } from "./guards/guest.guard";
import { PipesModule } from "./pipes/pipes.module";
import { CurrencyMaskDirective } from "./directives/currency-mask.directive";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { ToastrModule } from "ngx-toastr";
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";

registerLocaleData(localePt);

const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxDropzoneModule,
    NgxMatSelectSearchModule,
    PipesModule,
    CurrencyMaskDirective,
    CKEditorModule,
    NgxGpAutocompleteModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
    }),
  ],
  providers: [
    AuthGuard,
    GuestGuard,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
  exports: [
    FormsModule,
    MaterialModule,
    SharedModule,
    NgxMatSelectSearchModule,
    NgxMaskModule,
    PipesModule,
    CurrencyMaskDirective,
    CKEditorModule,
    NgxGpAutocompleteModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
