<button class="picker-button" matRipple #toggle [matMenuTriggerFor]="pickerMenu">
  <i class="fas fa-calendar-alt"></i>
  <span class="date-span">{{ getLabel() }}</span>
</button>
<mat-menu #pickerMenu class="dialog-panel">
  <div class="dialog-picker" (click)="$event.stopPropagation(); $event.preventDefault()">
    <div class="wrapper">
      <div class="calendar">
        <mat-calendar [selected]="value" (selectedChange)="onSelectedChange($event)" [dateClass]="dateClass" #calendar
          [headerComponent]="headerComponent"></mat-calendar>
      </div>
      <ul class="picker-menu">
        <li class="picker-menu__item" *ngFor="let item of modes" [class.active]="item.value === activeMode">
          <button (click)="handleActiveMode(item.value)" type="button">
            {{ item?.label }}
          </button>
        </li>
        <li class="picker-menu__item"></li>
      </ul>
    </div>
    <div class="dialog-picker__actions">
      <button mat-button type="button" class="mat-button-small" (click)="onClear()" type="button" *ngIf="hasChanged">
        Limpar filtros
      </button>
      <button mat-stroked-button type="button" class="btn btn-outlined mat-button-small" (click)="onDismiss()">
        CANCELAR
      </button>
      <button mat-raised-button color="primary" [disabled]="isDisabled()" class="btn btn-default mat-button-small"
        type="submit" (click)="onSubmit()">
        FILTRAR
      </button>
    </div>
  </div>
</mat-menu>
