import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// pages
import { AuthRootComponent } from "./auth/auth-root/auth-root.component";
import { GuestGuard } from "./guards/guest.guard";
import { AuthGuard } from "./guards/auth.guard";
import { AdminRootComponent } from "./admin/admin-root/admin-root.component";

const routes: Routes = [
  {
    path: "auth",
    component: AuthRootComponent,
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    canActivate: [GuestGuard],
  },
  {
    path: "",
    component: AdminRootComponent,
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/not-found",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
