import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import { DialogComponent } from "./components/dialog/dialog.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { UploadSectionComponent } from "./components/upload-section/upload-section.component";
import { MaterialModule } from "../material/material.module";
import { ButtonContainerComponent } from './components/button-container/button-container.component';
import { DatePickerHeaderComponent } from "./components/date-picker-header/date-picker-header.component";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";

@NgModule({
  declarations: [DialogComponent, LoadingComponent, UploadSectionComponent, ButtonContainerComponent, DatePickerHeaderComponent, DatePickerComponent],
  imports: [CommonModule, SharedRoutingModule, MaterialModule],
  exports: [LoadingComponent, UploadSectionComponent, ButtonContainerComponent, DatePickerComponent],
})
export class SharedModule { }
