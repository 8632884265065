import { GlobalService } from "./global.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Usuario } from "../models/usuario.model";
import { HelperService } from "./helper.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "@environments/environment";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  redirectUrl = "";
  isRecuperarSenha: boolean = false;
  token: string;
  user: any;

  constructor(
    public http: HttpClient,
    public global: GlobalService,
    private helper: HelperService,
    private toastService: ToastrService,
  ) {
    this.setToken(localStorage.getItem("token_chappa"));
    this.user = this.getUserStorage();
  }

  async signIn(params: { usuario: string; senha: string }) {
    const res = await this.helper.subscribeToObservable(
      this.http.get<Usuario>(environment.baseUrl + "/usuario/autenticar", {
        params,
      })
    );

    if (!res?.autenticacao?.sucesso) {
      this.toastService.error(res?.autenticacao?.erro);
      return "failure";
    }

    if (res.indAdmin !== "S") {
      this.toastService.error(
        "Seu usuário não tem permissão para acessar essa área!"
      );
      return "failure";
    }

    // if (
    //   res.solicitaAlteracao == "S" &&
    //   this.usuario.senha == res.codigoRecuperacao
    // ) {
    //   this.auth.isRecuperarSenha = true;
    //   const dialogRef = this.dialog.open(ModalResetPasswordComponent, {
    //     width: "420px",
    //   });
    //   dialogRef.afterClosed().subscribe(() => {});
    //   return;
    // }

    this.user = res;
    this.setToken(btoa(params?.usuario + ":" + params?.senha));
    this.setUser(res);

    return "success";
  }

  login(user) {
    return this.http.get(
      `${environment.baseUrl}/usuario/autenticar?usuario=${user.email}&senha=${user.senha}`
    );
  }

  getAuthorizationHeader() {
    return new HttpHeaders().append("Authorization", "Basic " + this.token);
  }

  getUserStorage() {
    return JSON.parse(localStorage.getItem("user_chappa"));
  }

  setToken(token) {
    if (!token) {
      return;
    }

    this.token = token;
    localStorage.setItem("token_chappa", this.token);
  }

  setUser(user) {
    if (!user) {
      return;
    }

    localStorage.setItem("user_chappa", JSON.stringify(user));
    this.user = this.getUserStorage();
  }

  getUser(user) {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.baseUrl + "/usuario/buscar?email=" + user.email)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  refreshUserLogged() {
    return this.http.get<Usuario>(environment.baseUrl + `/usuario/buscar?id=${this.user?.id}`, {
      headers: this.getAuthorizationHeader(),
    }).pipe(
      map((res) => {
        this.setUser(res);

        return res;
      })
    );
  }

  logout() {
    this.token = undefined;
    this.user = null;
    localStorage.clear();
  }

  isLoggedIn() {
    if (!this.token) {
      if (localStorage.getItem("token_chappa")) {
        this.setToken(localStorage.getItem("token_chappa"));
        this.user = this.getUserStorage();
      } else {
        return false;
      }
    }

    return true;
  }

  async existsEmail(email: string) {
    return await this.helper.subscribeToObservable(
      this.http.get<boolean>(environment.baseUrl + "/usuario/existeEmail", {
        params: {
          id: -99,
          email,
          operacao: "insert",
        },
      })
    );
  }

  async sendVerificationCode(usuario: string) {
    const res = await this.helper.subscribeToObservable(
      this.http.get<string>(
        environment.baseUrl + "/usuario/codigoVerificacao",
        {
          params: {
            usuario,
          },
        }
      )
    );

    return res === "Ok";
  }

  async forgotPassword(email: string) {
    const exists = await this.existsEmail(email);

    if (!exists) {
      this.toastService.error(
        "Esse e-mail não existe ou nunca foi cadastrado.",
        "Esqueceu senha"
      );
      return "failure";
    }

    const res = await this.sendVerificationCode(email);

    return res ? "success" : "failure";
  }

  async updatePassword(params: {
    usuario: string;
    senha: string;
    codigoVerificacao: string;
  }) {
    const res = await this.helper.subscribeToObservable(
      this.http.get<Usuario>(environment.baseUrl + "/usuario/alterarSenha", {
        params,
      })
    );

    if (!res?.id) return;

    res.senha = params?.senha;
    return res;
  }
}
