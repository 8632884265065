import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "button-container",
  templateUrl: "./button-container.component.html",
  styleUrls: ["./button-container.component.scss"],
})
export class ButtonContainerComponent implements OnInit {
  @Input("isLoading") isLoading?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
