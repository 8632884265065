import { AuthService } from '@services/auth.service';
import { firstValueFrom } from 'rxjs';

export function initializeApp(
  authService: AuthService
) {
  return () =>
    new Promise(async (resolve) => {

      const isLoggedIn = authService.isLoggedIn();

      if (isLoggedIn) {
        await firstValueFrom(authService.refreshUserLogged());
      }

      resolve(true);
    });
}
