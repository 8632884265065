import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
  MatCalendarCellClassFunction,
} from '@angular/material/datepicker';
import { MatMenu } from '@angular/material/menu';
import { HelperService } from '@services/helper.service';
import { DatePickerHeaderComponent } from '../date-picker-header/date-picker-header.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePickerService } from '@services/date-picker.service';

interface ModeItem {
  label: string;
  value: DatePickerActiveModeType;
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor
{
  @ViewChild('pickerMenu') pickerMenu?: MatMenu;
  @ViewChild('calendar') calendarRef?: MatCalendar<DateRange<Date> | Date>;

  @Input('selectedDateRange') selectedDateRange: any; // excluri dps
  @Input('initialMode')
  initialMode?: DatePickerActiveModeType;

  headerComponent = DatePickerHeaderComponent;

  dialogPosition = {
    left: 0,
    right: 0,
  };
  activeMode: DatePickerActiveModeType = 'last-30-days';
  modes: ModeItem[] = [
    { label: 'Hoje', value: 'today' },
    { label: 'Data única', value: 'single-date' },
    { label: 'Esta semana', value: 'current-week' },
    { label: 'Semana passada', value: 'last-week' },
    { label: 'Este mês', value: 'current-month' },
    { label: 'Mês passado', value: 'last-month' },
    { label: 'Últimos 30 dias', value: 'last-30-days' },
    { label: 'Período customizado', value: 'customized' },
  ];

  hasChanged = false;

  isDateRangeTriggered = false;
  _value = new DateRange(new Date(), new Date());

  constructor(
    private helper: HelperService,
    private cdRef: ChangeDetectorRef,
    private datePickerService: DatePickerService
  ) {}

  get value(): any {
    return this._value;
  }

  set value(v: DateRange<Date>) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  writeValue(value: DateRange<Date>) {
    this._value = value;
    this.onChange(value);
  }

  onChange = (_: any) => {};
  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const initialModeChanges = changes?.['initialMode'];

    if (initialModeChanges && !initialModeChanges?.firstChange) {
      this.activeMode = initialModeChanges?.currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.handleActiveMode(this.initialMode);

    this.cdRef.detectChanges();
  }

  handleActiveMode(active: DatePickerActiveModeType) {
    this.activeMode = active;
    this.hasChanged = true;

    const dateRangeValue = this.datePickerService.getDateRangeValue(
      active,
      this.value
    );

    this.value = dateRangeValue;

    if (this.calendarRef) {
      this.calendarRef._goToDateInView(this.value.start, 'month');
    }
  }

  public isDisabled(): boolean {
    return !this.value?.start || !this.value?.end;
  }

  public onSubmit(): void {
    if (!this.value?.start || !this.value?.end) {
      return;
    }

    this.onChange(this.value);
    this.pickerMenu.closed.next('click');
  }

  public onDismiss(): void {
    this.pickerMenu.closed.next('click');
  }

  dateClass: MatCalendarCellClassFunction<DateRange<Date> | Date> = (
    cellDate,
    view
  ) => {
    return 'date-picker-day';
  };

  public getLabel(): string {
    if (!this.value?.start && !this.value?.end) {
      return 'Selecionar intervalo de datas';
    }

    const startDate = this.helper
      .moment(this.value?.start)
      .format('DD/MM/YYYY');

    const endDate = this.helper.moment(this.value?.end).format('DD/MM/YYYY');

    if (this.helper.moment(startDate).isSame(endDate, 'day')) {
      return startDate;
    }

    return `${startDate} - ${endDate}`;
  }

  public onSelectedChange(event: DateRange<Date> | Date) {
    if (event instanceof Date) {
      if (this.value && event && this.activeMode === 'single-date') {
        this.value = new DateRange(event, event);
        this.hasChanged = true;
      } else if (
        this.value &&
        this.value.start &&
        event > this.value.start &&
        !this.value.end
      ) {
        this.activeMode = 'customized';
        this.value = new DateRange(this.value.start, event);
        this.hasChanged = true;
      } else {
        this.value = new DateRange(event, null);
      }
    }

    if (event instanceof DateRange) {
      this.value = event;
    }
  }

  public onClear() {
    this.hasChanged = false;
    // this.handleActiveMode(this.initialMode);
    this.value = new DateRange(undefined, undefined);
    this.onChange(this.value);
    this.pickerMenu.closed.next('click');
  }
}
