import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  Optional,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskInputMode,
} from "@lib/currency-mask/currency-mask.config";
import { CurrencyMaskHandler } from "@lib/currency-mask/currency-mask.handler";

@Directive({
  standalone: true,
  selector: "[currency-mask]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyMaskDirective),
      multi: true,
    },
  ],
})
export class CurrencyMaskDirective {
  @Input() options: Partial<CurrencyMaskConfig> = {};

  private _handler!: CurrencyMaskHandler;
  private readonly _keyValueDiffer: KeyValueDiffer<
    keyof CurrencyMaskConfig,
    unknown
  >;

  private _optionsTemplate: CurrencyMaskConfig;

  constructor(
    @Optional()
    @Inject(CURRENCY_MASK_CONFIG)
    globalOptions: Partial<CurrencyMaskConfig>,
    keyValueDiffers: KeyValueDiffers,
    private readonly _elementRef: ElementRef
  ) {
    this._optionsTemplate = {
      inputMode: CurrencyMaskInputMode.FINANCIAL,
      align: "left",
      allowNegative: true,
      allowZero: true,
      decimal: ",",
      precision: 2,
      prefix: "R$ ",
      suffix: "",
      thousands: ".",
      nullable: true,
      ...globalOptions,
    };

    this._keyValueDiffer = keyValueDiffers.find({}).create();
  }

  ngOnInit() {
    this._handler = new CurrencyMaskHandler(this._elementRef.nativeElement, {
      ...this._optionsTemplate,
      ...this.options,
    });
  }

  ngAfterViewInit() {
    this._elementRef.nativeElement.style.textAlign =
      this.options?.align ?? this._optionsTemplate.align;
  }

  ngDoCheck() {
    if (this._keyValueDiffer.diff(this.options)) {
      this._elementRef.nativeElement.style.textAlign =
        this.options?.align ?? this._optionsTemplate.align;

      this._handler.updateOptions({
        ...this._optionsTemplate,
        ...this.options,
      });
    }
  }

  @HostListener("blur", ["$event"])
  handleBlur(event: FocusEvent) {
    this._handler.getOnModelTouched().apply(event);
  }

  @HostListener("cut")
  handleCut() {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this._handler.handleCut();
    }
  }

  @HostListener("input")
  handleInput() {
    if (this.isChromeAndroid()) {
      !this.isReadOnly() && this._handler.handleInput();
    }
  }

  @HostListener("keydown", ["$event"])
  handleKeydown(event: KeyboardEvent) {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this._handler.handleKeydown(event);
    }
  }

  @HostListener("keypress", ["$event"])
  handleKeypress(event: KeyboardEvent) {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this._handler.handleKeypress(event);
    }
  }

  @HostListener("paste")
  handlePaste() {
    if (!this.isChromeAndroid()) {
      !this.isReadOnly() && this._handler.handlePaste();
    }
  }

  @HostListener("drop", ["$event"])
  handleDrop(event: DragEvent) {
    if (!this.isChromeAndroid()) {
      event.preventDefault();
    }
  }

  isChromeAndroid(): boolean {
    return (
      /chrome/i.test(navigator.userAgent) &&
      /android/i.test(navigator.userAgent)
    );
  }

  isReadOnly(): boolean {
    return this._elementRef.nativeElement.hasAttribute("readonly");
  }

  registerOnChange(callbackFunction: (value: number | null) => void): void {
    this._handler.setOnModelChange(callbackFunction);
  }

  registerOnTouched(callbackFunction: () => void): void {
    this._handler.setOnModelTouched(callbackFunction);
  }

  setDisabledState(isDisabled: boolean): void {
    this._elementRef.nativeElement.disabled = isDisabled;
  }

  writeValue(value: number): void {
    this._handler.setValue(value);
  }
}
